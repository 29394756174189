@import '../thirdparty/bs3-custom-vars.less';

.rule-701-recipients {
  .rule-701-section {
    padding: 25px 0;

    &:not(:last-child) {
      border-bottom: 1px solid @fps-process-line;
    }
  }

  .rule-701-header {
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 15px;
  }

  sb-form.no-field-labels {
    sb-field-label,
    .fas.fa-exclamation-triangle {
      display: none;
    }
  }

  sb-dictionary .iter-widget-colon {
    display: none;
  }
}

.rule-701-docs {
  ul {
    list-style: none;
    padding: 0;
    margin-top: 40px;
  }

  li {
    padding: 10px 0;

    sbx-icon {
      margin-right: 10px;
      color: @fds-neutral-500;

      i {
        transform: scaleY(-1);
      }
    }
  }
}
