.new-constraints {
  .constraint-subheading {
    font-size: 19px;
    margin-bottom: 0;
  }

  .constraints {
    .overlay {
      top: 50%;
      left: 50%;
      width: 400px;
      margin-left: -200px;
      z-index: 1001;
      position: fixed;
      .spinner {
        text-align: center;
        font-size: 48px;
      }
    }

    @token-width: 130px;
    @help-height: 40px;
    .header {
      display: flex;
      align-items: center;

      *:not(:nth-child(2)) {
        display: inline-block;
        text-decoration: none;

        &:before {
          display: inline-block;
        }
      }

      .icons {
        margin-left: auto;
      }
    }

    .status {
      margin-left: 15px;
    }

    .symbol {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      font-weight: 600;
      margin-top: @help-height;
      width: @help-height;
    }

    .or {
      width: 60px;
      background-color: @sb-gray-light;
      color: white;
      margin-left: 10px;
      font-size: xx-large;
    }

    .satisfies {
      content: '\f00c';
      color: @sb-green;
    }

    .notifies {
      content: '\f00e';
      color: @sb-yellow;
    }

    .fails {
      content: '\f00d';
      color: @sb-red;
    }

    .panel-body {
      display: flex;
      flex-direction: column;
      padding-top: 0px;
    }

    .constraint {
      display: flex;
      flex-direction: column;
      margin: 8px 0;
      margin: 8px 0;

      .alert {
        margin-bottom: 0;
      }

      .description {
        margin: 5px;
        font-weight: bold;
        font-size: 14px;
        color: @fps-text;
      }

      .formula {
        display: flex;
        margin: 0 20px;

        sb-constraint-token.op-token {
          // Flex align to not obscure ?'s popovers in title row
          align-self: flex-end;
          height: 40px;

          .symbol {
            margin-top: 0px;
          }
        }
        .token {
          display: flex;
          flex-direction: column;
          height: 80px;
          .info {
            max-width: @token-width;
            text-transform: uppercase;
            font-size: 14px;
            height: @help-height;
            line-height: @help-height;
            vertical-align: middle;
            font-weight: 600;
            white-space: nowrap;
          }

          .value {
            width: @token-width;
            background-color: @sb-gray-light;
            font-size: 16px;
            color: @fps-text;
            font-weight: bold;
            padding: 8px;
            overflow: hidden;
            text-overflow: clip;
          }
        }
      }
    }

    .combined-row {
      display: flex;
    }

    .combined {
      .year {
        margin: 5px;
        font-weight: bold;
        font-size: 14px;
        color: @fps-text;

        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
        padding-bottom: 5px;
      }

      .status {
        margin-left: 0;
      }

      .combined-row {
        .token .value {
          padding: 5px;
          margin: 5px;
        }

        &:nth-child(2n) {
          background-color: @fps-list-odd;
        }

        &:not(:first-child) {
          .symbol {
            margin-top: 0;
          }
          .token {
            height: 40px;
            .operator,
            .symbol {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

/** OLD STYLING FOR BACKWARD COMPATIBILITY **/
/* TODO remove when old grant shares processes are done */

.deprecated-constraints {
  .constraint-subheading {
    font-size: 19px;
    margin-bottom: 0;
  }

  .checking-para {
    margin: 0;
  }

  .constraint {
    .vertical-align() {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -mozilla-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    margin-bottom: 6pt;

    .status {
      float: left;
      width: 5%;
      text-align: center;
      font-size: 41px;
      padding-top: 15px;

      .satisfies:after {
        content: '\f00c';
        color: @sb-green;
      }

      .fails:after {
        content: '\f00d';
        color: @sb-red;
      }
    }

    .formula-description {
      margin-top: 5px;
      font-weight: bold;
      font-size: 17px;
      color: @fps-text;
    }

    .formula {
      .expression {
        float: left;
        width: 28.999999999%;
        text-align: center;

        > div {
          border: 3px solid @sb-gray-light;
        }

        .value {
          font-size: 30px;
          color: @fps-text-secondary;
          border-top: 3px solid @sb-gray-light;
        }

        .value:first-child {
          border: none;
        }

        .help {
          text-transform: uppercase;
          font-size: 14px;
          height: 40px;
          background-color: @sb-gray-light;
          > div {
            .vertical-align;
          }
        }
      } // end .expression

      .formula-label {
        color: @fps-text-secondary;
        font-size: 20px;
        float: left;
        width: 11%;
        text-align: center;
        height: 88px;
        > div {
          .vertical-align;
        }
      }

      .expression-4th {
        width: 25.3333333%;
      }

      .expression-5th {
        width: 20.7%;
      }

      .operator {
        color: @fps-text-secondary;
        float: left;
        width: 4%;
        font-size: 60px;
        text-align: center;
      } // end .operator

      .operator.le {
        margin-top: -3px;
      }
    } // end .formula
  }
}
